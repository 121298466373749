import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { ActivatedRouteSnapshot } from '@angular/router';
//import { RouterStateSnapshot } from '@angular/router';
//import { Http,RequestOptions } from '@angular/http';
//import { Http,RequestOptions } from '@angular/common/http';
import { HttpClient,HttpHeaders,HttpRequest,HttpParams
} from '@angular/common/http';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import {SERVICE_URL} from './config.service';
import {CommonFunctionsHandler} from './commonFunctions.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogCommonDialog } from './../shared/dialog-common';

@Injectable()
export class ServiceHandler {
  
  //user = new BehaviorSubject(undefined);
  //sessionLoaded = new BehaviorSubject(false);
  //userdata:any = {};
  //noAuthCount:any = 0;
  public settings:Settings;
  apiCalling=false;
  constructor(private http:HttpClient,private router:Router,private cfh:CommonFunctionsHandler,
    public appSettings:AppSettings,public dialog: MatDialog) {
    this.settings = this.appSettings.settings;
   }
  //import { HttpClient, HttpHeaders } from '@angular/common/http';

//  let headers = new HttpHeaders({
//     'Content-Type': 'application/json'
//  });
//  let options = {
//     headers: headers
//  }

//  this.http.post(URL, param, options)
//     .subscribe(data => {
//          console.log(data);
//  });
  setOptions(){
    let options = {
      withCredentials: true
    };
    return options;
  }
  doGetWithoutLoader(url) {
    return new Promise((resolve, reject) => {
      this.http.get(SERVICE_URL + url,this.setOptions())
        .subscribe((res:any) => {
          if(this.checkLoggedIn(res.code,res.message) && this.checkAuthAssigned(res.code,res.message)){
            resolve(res);
          }else{
            this.handleErrors(res);
            reject();
          }
      },(error:any)=>{
        this.router.navigateByUrl('/auth/error');
      });
    });
  }
  doGet(url) {
    this.settings.loadingSpinner=true;
    return new Promise((resolve, reject) => {
      this.http.get(SERVICE_URL + url,this.setOptions())
        .subscribe((res:any) => {
          this.settings.loadingSpinner=false;
          if(this.checkLoggedIn(res.code,res.message) && this.checkAuthAssigned(res.code,res.message)){
            resolve(res);
          }else{
            this.handleErrors(res);
            reject();
          }
      },(error:any)=>{
        this.router.navigateByUrl('/auth/error');
      });
    });
  }

  doGetbyId(url,id) {
    //$(".overlay").show();
   // console.log("in handler");
   //this.apiCalling=true;
   this.settings.loadingSpinner=true;
    return new Promise((resolve, reject) => {
      this.http.get(SERVICE_URL + url+'/'+id,this.setOptions())
        //.map(res => res.json())
        .subscribe((res:any) => {
            //console.log("response got");
            //console.log(res);
          //$(".overlay").hide();
         // this.apiCalling=false;
          this.settings.loadingSpinner=false
          // console.log("in doGetbyId");
          // console.log("login : "+this.checkLoggedIn(res.code,res.message)+" | auth assign : "+this.checkAuthAssigned(res.code,res.message));
          if(this.checkLoggedIn(res.code,res.message) && this.checkAuthAssigned(res.code,res.message)){
            resolve(res);
          }else{
            this.handleErrors(res);
            reject();
          }
      },(error:any)=>{
        this.router.navigateByUrl('/auth/error');});
      });
  }
  
  doPost(url, data) {
    //$(".overlay").show();
    this.settings.loadingSpinner=true;
    //this.apiCalling=true;
    return new Promise((resolve, reject) => {
      this.http.post(SERVICE_URL + url, data,this.setOptions())
        //.map(res => res.json())
        .subscribe((res:any) => {
          
          //$(".overlay").hide(100,function(){
            // this.checkLoggedIn(res.code);
            // if(this.noAuthCount == 1){
            //   this.noAuthCount = 0;
            // }else{
            //   this.checkAuthAssigned(res.code);
            // }
            // resolve(res);
           // this.apiCalling=false;
            this.settings.loadingSpinner=false;
          //   console.log("in doPost");
          // console.log("login : "+this.checkLoggedIn(res.code,res.message)+" | auth assign : "+this.checkAuthAssigned(res.code,res.message));
            if(this.checkLoggedIn(res.code,res.message) && this.checkAuthAssigned(res.code,res.message)){
              resolve(res);
            }else{
              
              this.handleErrors(res);
              
              reject();
            }

          //}.bind(this));
        },(error:any)=>{
          // console.log(error.status);
          this.router.navigateByUrl('/auth/error');});
      });
  }

  
  doPut(url, data) {
    //this.apiCalling=true;
    this.settings.loadingSpinner=true;
    return new Promise((resolve, reject) => {
      this.http.put(SERVICE_URL + url, data,this.setOptions())
        //.map(res => res.json())
        .subscribe((res:any) => {
          //$(".overlay").hide(100,function(){
            // this.checkLoggedIn(res.code);
            // if(this.noAuthCount == 1){
            //   this.noAuthCount = 0;
            // }else{
            //   this.checkAuthAssigned(res.code);
            // }
            // resolve(res);
            //this.apiCalling=false;
            this.settings.loadingSpinner=false;
          //   console.log("in doPut");
          // console.log("login : "+this.checkLoggedIn(res.code,res.message)+" | auth assign : "+this.checkAuthAssigned(res.code,res.message));
            if(this.checkLoggedIn(res.code,res.message) && this.checkAuthAssigned(res.code,res.message)){
                resolve(res);
              }else{
                this.handleErrors(res);
                reject();
              }

          //}.bind(this));
        },(error:any)=>{
          //console.log('need to handle error in handler');
          // console.log(error);
          // console.log(error.status);
          this.router.navigateByUrl('/auth/error');
          reject(error);
        });
          
      });
  }

  doDelete(url) {
    //$(".overlay").show();
    //this.apiCalling=true;
    this.settings.loadingSpinner=true;
    return new Promise((resolve, reject) => {
      this.http.delete(SERVICE_URL + url, this.setOptions())
        //.map(res => res.json())
        .subscribe((res:any) => {
          //$(".overlay").hide(100,function(){
        //   this.checkLoggedIn(res.code);
        //   if(this.noAuthCount == 1){
        //     this.noAuthCount = 0;
        //   }else{
        //     this.checkAuthAssigned(res.code);
        //   }
        //   resolve(res);
        //this.apiCalling=false;
        this.settings.loadingSpinner=false;
        // console.log("in doDelete");
        //   console.log("login : "+this.checkLoggedIn(res.code,res.message)+" | auth assign : "+this.checkAuthAssigned(res.code,res.message));
        if(this.checkLoggedIn(res.code,res.message) && this.checkAuthAssigned(res.code,res.message)){
            resolve(res);
          }else{
            this.handleErrors(res);
            reject();
        }
      },(error:any)=>{
        this.router.navigateByUrl('/auth/error');});
      });
  }

  public load(){
    this.apiCalling=true;
    this.settings.loadingSpinner=true;
    // console.log("go to check session1");
    return new Promise((resolve, reject) => {
      //console.log('here');  
      // console.log("go to check session2");
      return this.http.get(SERVICE_URL + "auth/session",this.setOptions())
        //.map(res => res.json())
        .subscribe((res:any)=> {
        //   console.log("session")
        //  console.log(res)
          //if(res.code == 200){
          //$(".overlay").hide();
            // this.checkLoggedIn(res.code);
            // if(this.noAuthCount == 1){
            //   this.noAuthCount = 0;
            // }else{
            //   this.checkAuthAssigned(res.code);
            // }
            //resolve(res);
          // }.bind(this));
          // console.log("get Session Response");
          // console.log(res);
          this.apiCalling=false;
          this.settings.loadingSpinner=false;
          if(res.code == 200){
            var userdata:any = {};
            if(res.data.loggedIn){
              userdata = {loggedIn:true};
              userdata.user_details=res.data.user_details;
            }else{
              userdata = res.data;
              //this.openErrorDialog(res.code,res.message);
            }
            this.cfh.setUser(userdata);
          }else{
            
          }
          this.cfh.sessionLoaded.next(true);
          return resolve(true);       
        },(error:any)=>{
          this.router.navigateByUrl('/auth/error');
          return resolve(false); 
        });
    });
  }
  
  handleTimeout(){
    return null;
  }
  
  handleErrors(res){
    //console.log(res.code+" : "+res.message);
  }
  
  checkLoggedIn(code,message){
    if(code != 408){
      return true;
    }else{
      //alert("You need to login first.")
      this.cfh.setUser({loggedIn:false});
      this.router.navigateByUrl('/auth/login');
      this.openErrorDialog(code,message);
      return false;
    }
  }

  checkAuthAssigned(code,message){
    if(code != 406){
      return true;
    }else{
      //this.noAuthCount++;
      //alert('You do not have access of this Module.');
      
      //window.history.go(-1);
      this.openErrorDialog(code,message);
      return false;
    }
  }

  openErrorDialog(code,message):void {
    // console.log(this.dialog)
    // console.log(code)
    // console.log(message)
        let dialogRef = this.dialog.open(DialogCommonDialog, {
          data: { code : code,message:message,path:'/assets/images/warning.svg'},
          disableClose:true,
          autoFocus : true
        });
        // console.log(dialogRef)
        dialogRef.afterClosed().subscribe(result => {
          //console.log('The dialog was closed');
        });
    
      }
}
