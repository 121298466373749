import { Component, OnInit, Inject } from '@angular/core';
import { ServiceHandler } from '../../Utility/serviceHandler.service';
import { FormBuilder, FormGroup, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonFunctionsHandler } from './../../Utility/commonFunctions.service';
import { DialogCommonDialog } from '../../shared/dialog-common';
import { NotificationService } from '../../shared/notification.service';
@Component({
  selector: 'change-password-dialog',
  templateUrl: 'dialog.component.html',
})
export class DialogComponent {
  public changePasswordForm: FormGroup;
  activeDisable = true;
  submitClicked = false;
  code:any;
  message:any;
  path:any;
  private RequestValue={
    password:'',
    new_password:''
  };
  constructor(public form: FormBuilder,
    private sh:ServiceHandler,
    public dialog: MatDialog,
    private cfh:CommonFunctionsHandler,
    private notification:NotificationService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     
     this.createForm();
      
  }
  createForm(){
    this.changePasswordForm =  this.form.group({
        old_password: ["",Validators.compose([Validators.required])],
        new_password: ["",Validators.compose([Validators.required,Validators.pattern(/^(?=.*\d)[a-zA-Z\d\-!@#\$%\^&*\(\)]{6,25}$/)])],
        confirm_new_password: ["",Validators.compose([Validators.required,Validators.pattern(/^(?=.*\d)[a-zA-Z\d\-!@#\$%\^&*\(\)]{6,25}$/)])],
      }, { validator: this.checkPasswords});
  }
  get old_password() {
    return this.changePasswordForm.get('old_password');
  }
  get new_password() {
    return this.changePasswordForm.get('new_password');
  }
  get confirm_new_password() {
    return this.changePasswordForm.get('confirm_new_password');
  }
  //Check Password
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.new_password.value;
    let confirmPass = group.controls.confirm_new_password.value;

    return pass === confirmPass ? null : { notSame: true }
  }
  
  doUserEvent(id) {
    //console.log(id);
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onSubmit() {
    
    if(this.changePasswordForm.valid){
    var formValue=this.changePasswordForm.getRawValue();;
    var userDetails=this.cfh.user.value.user_details;
      
      if(formValue.old_password){
        this.RequestValue.password=formValue.old_password;
      }
      if(formValue.new_password){
        this.RequestValue.new_password=formValue.new_password;
      }
      this.submitClicked = true;
      this.sh.doPut("user/change-password/"+userDetails.id,this.RequestValue).then((data:any) => {
        //console.log("data in response")
        //console.log(data)
        if(data.code == 200){
          this.dialogRef.close(true);
          this.message=data.message;
          this.path='/assets/images/alert.svg';
          this.openErrorDialog();
          // this.notification.success(data.message);
        }else{
          this.code=data.code;
          if(data.code == 408){
            this.dialogRef.close(true);
          }
          this.message=data.message;
          this.path='/assets/images/warning.svg';
          this.openErrorDialog();
        }
      }).catch((err) => {
        if(err){
          this.message=err.message;
          this.path='/assets/images/warning.svg';
          this.openErrorDialog();
        }else{
          this.dialogRef.close(true);
        }
          // if(err.code == 408){
          //   this.dialogRef.close(true);
          // }
        //console.log(err);
        //this.code=err.code;
        
      })
  }
}
/**
   * Open Error dialog box
   */
  openErrorDialog():void {
    let dialogRef = this.dialog.open(DialogCommonDialog, {
      data: { code : this.code,message:this.message,path:this.path },
      disableClose:true
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  
}

