import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {ServiceHandler} from '../../../Utility/serviceHandler.service';
import { Menu } from './menu.model';
import { verticalMenuItems, horizontalMenuItems } from './menu';
import { CommonFunctionsHandler } from './../../../Utility/commonFunctions.service';

@Injectable()
export class MenuService {

  constructor(private location:Location,
              private router:Router,
              private sh:ServiceHandler,
              private cfh:CommonFunctionsHandler){ } 
    
  // public getVerticalMenuItems() {
  //   let HotelGroup:any;
  //   let Hotels:[];
  //   this.data().then((data)=>{
  //     console.log(data);
  //   })
  // }
  getVerticalMenuItemsNew(){
    return new Promise((resolve, reject) => {
      
      let HotelGroup:any;
      //if(this.cfh.selectedHotel==""){
      this.sh.doGetWithoutLoader("hotel-group/hotel").then((data:any) => {
        if(data.code == 200){
          console.log("==============data================");
          console.log(data);
          // hotel_group hotel_name inncode timezone_offset
          // type: "hg"
            HotelGroup=data.data;
            var menu = [];
            var index = 1;
            var indexOne = 1;
            var newMenu = data.data;
            var mInncode = '';
            var iInncode = '';
            let inncodeOffset = {};
            let inncode_hyatt_code = {};
            for(var i=0;i<HotelGroup.hotel_group.length;i++){
              var item2={};
              var icon = 'hotel';
              var hasHotels = false;
              if(HotelGroup.hotel_group[i].type == 'hg'){
                icon = 'business';
                hasHotels = true;
              }else{
                HotelGroup.hotel_group[i].name = HotelGroup.hotel_group[i].name +" ("+HotelGroup.hotel_group[i].inncode+")";
                let hyatt_code = HotelGroup.hotel_group[i].inncode;
                if(HotelGroup.hotel_group[i].hyatt_code && HotelGroup.hotel_group[i].hyatt_code.length){
                  hyatt_code = HotelGroup.hotel_group[i].hyatt_code;
                }
                inncode_hyatt_code[HotelGroup.hotel_group[i].inncode]=hyatt_code;
                HotelGroup.hotel_group[i].hyatt_name = HotelGroup.hotel_group[i].name +" ("+hyatt_code+")";
                inncodeOffset[HotelGroup.hotel_group[i].inncode] = HotelGroup.hotel_group[i].timezone_offset;
              }
              var item = new Menu (index, HotelGroup.hotel_group[i].name, '/', null, icon, null, hasHotels, 0);
              menu.push(item);
              
              
              if(HotelGroup.hotel_group[i].hotel_name){
                for(var j=0;j<HotelGroup.hotel_group[i].hotel_name.length;j++){
                  HotelGroup.hotel_group[i].hotel_name[j].name = HotelGroup.hotel_group[i].hotel_name[j].name+" ("+HotelGroup.hotel_group[i].hotel_name[j].inncode+")";
                  let hyatt_code = HotelGroup.hotel_group[i].hotel_name[j].inncode;
                if(HotelGroup.hotel_group[i].hotel_name[j].hyatt_code && HotelGroup.hotel_group[i].hotel_name[j].hyatt_code.length){
                  hyatt_code = HotelGroup.hotel_group[i].hotel_name[j].hyatt_code;
                }
                HotelGroup.hotel_group[i].hotel_name[j].hyatt_name = HotelGroup.hotel_group[i].hotel_name[j].name +" ("+hyatt_code+")";
                  item2= new Menu (indexOne, HotelGroup.hotel_group[i].hotel_name[j].name, '/', null, '', null, false, index);
                  menu.push(item2);
                  inncode_hyatt_code[HotelGroup.hotel_group[i].hotel_name[j].inncode]=hyatt_code;
                  inncodeOffset[HotelGroup.hotel_group[i].hotel_name[j].inncode] = HotelGroup.hotel_group[i].hotel_name[j].timezone_offset;
                }
                HotelGroup.hotel_group[i].hotel_name = HotelGroup.hotel_group[i].hotel_name.sort(function (a, b) {
                  if ( a.name < b.name ){
                    return -1;
                  }
                  if ( a.name > b.name ){
                    return 1;
                  }
                  return 0;
                });
                
              }
              indexOne++;
              index++;
            }
            this.cfh.inncodeOffset = inncodeOffset;
            this.cfh.inncode_hyatt_code = inncode_hyatt_code;
            console.log(inncodeOffset);
            console.log(inncode_hyatt_code);
            
            var selectedItem = {
                type:"hotel-group",
                id:HotelGroup.hotel_group[0].id,
                name:HotelGroup.hotel_group[0].name,
                inncode:HotelGroup.hotel_group[0].inncode,
                brand:HotelGroup.hotel_group[0].brand
            };
            if(this.cfh.selectedHotel==""){
              if (sessionStorage.getItem("selectedData") === null) {
                this.cfh.selectedHotel = HotelGroup.hotel_group[0].name;
                this.cfh.selectedInncode = HotelGroup.hotel_group[0].inncode;
                this.cfh.setSelectedItem({data:selectedItem});
                this.cfh.dashboardRequestData.inncode = selectedItem.inncode;
                var reportMenu = [];
                if(HotelGroup.hotel_group[0].type=='h'){
                  var menuItem = {
                        "name":HotelGroup.hotel_group[0].name,
                        "id":HotelGroup.hotel_group[0].inncode,
                        "brand":HotelGroup.hotel_group[0].brand
                  };
                  reportMenu.push(menuItem);
                }else{
                  for(var i=0;i<HotelGroup.hotel_group[0].hotel_name.length;i++){
                    var menuItem1 = {
                      "name":HotelGroup.hotel_group[0].hotel_name[i].name,
                      "id":HotelGroup.hotel_group[0].hotel_name[i].inncode,
                      "brand":HotelGroup.hotel_group[0].hotel_name[i].brand
                    };
                    reportMenu.push(menuItem1);
                  }
                }
                this.cfh.dashboardRequestData.hotelData = reportMenu;

                this.cfh.userHotels=menu;
                var selectedData = {
                  selectedHotel : this.cfh.selectedHotel,
                  selectedInncode : this.cfh.selectedInncode,
                  dashboardRequestData : this.cfh.dashboardRequestData
                };
                console.log('dashboard request data selected in menu service')
                sessionStorage.setItem("selectedData",JSON.stringify(selectedData));
              }else{
                var sessionSelectedData = JSON.parse(sessionStorage.getItem("selectedData"));
                  this.cfh.selectedHotel = sessionSelectedData.selectedHotel;
                  this.cfh.selectedInncode =sessionSelectedData.selectedInncode;
                  this.cfh.dashboardRequestData = sessionSelectedData.dashboardRequestData;
              }
              console.log('dashboard request data selected in menu service1')
              
              this.cfh.onFirstComponentButtonClick();
            }
            
            
            //return resolve(menu);
            return resolve(HotelGroup);
      }else{
        // this.code=data.code;
        // this.message=data.message;
        // this.openDialog();
        return reject(data.code);
      }
    }).catch((err) => {
      
      return reject(err.code);
    })
  // }else{

  // }
    })
  }
  getVerticalMenuItems(){
    return verticalMenuItems;
  }

  public getHorizontalMenuItems():Array<Menu> {
    var menu = [];
    
    var modules = this.cfh.user.value.user_details.modules;
    var index = 1;
    for(var i=0;i<modules.length;i++){
      if(!modules[i].for_admin){
        var item = new Menu (index, modules[i].label, '/home/'+modules[i].module_name, null, modules[i].icon, null, false, 0);
        menu.push(item);
        index++;
      }
    }
    if(this.cfh.user.value.user_details.is_admin){
      var item = new Menu (index, 'Admin', '/ui/lists', null, 'verified_user', null, true, 0); 
      var adminIndex = index;
      menu.push(item);
      index++;
      for(var i=0;i<modules.length;i++){
        if(modules[i].for_admin){
          //var item = new Menu (index, modules[i].module_name, '/home/'+modules[i].module_name, null, 'keyboard', null, false, 0);
          var item = new Menu (index, modules[i].label, '/home/'+modules[i].module_name, null, modules[i].icon, null, false, adminIndex);
          menu.push(item);
          index++;
        }
      }
    }
    //console.log(menu);
    //console.log(JSON.stringify(menu));
    
    return menu;
    //console.log(horizontalMenuItems)
    //return horizontalMenuItems;
  }

  public expandActiveSubMenu(menu:Array<Menu>){
      let url = this.location.path();
      let routerLink = url; // url.substring(1, url.length);
      let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
      if(activeMenuItem[0]){
        let menuItem = activeMenuItem[0];
        while (menuItem.parentId != 0){  
          let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
          menuItem = parentMenuItem;
          this.toggleMenuItem(menuItem.id);
        }
      }
  }

  public toggleMenuItem(menuId){
    //console.log(menuId)
    let menuItem = document.getElementById('menu-item-'+menuId);
    let subMenu = document.getElementById('sub-menu-'+menuId);  
    if(subMenu){
      if(subMenu.classList.contains('show')){
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      }
      else{
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }      
    }
  }

  public closeOtherSubMenus(menu:Array<Menu>, menuId){
    let currentMenuItem = menu.filter(item => item.id == menuId)[0]; 
    if(currentMenuItem.parentId == 0 && !currentMenuItem.target){
      menu.forEach(item => {
        if(item.id != menuId){
          let subMenu = document.getElementById('sub-menu-'+item.id);
          let menuItem = document.getElementById('menu-item-'+item.id);
          if(subMenu){
            if(subMenu.classList.contains('show')){
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }              
          } 
        }
      });
    }
  }

  public closeAllSubMenus(){
      let menu = document.getElementById("vertical-menu");
      if(menu){
          for (let i = 0; i < menu.children[0].children.length; i++) {
              let child = menu.children[0].children[i];
              if(child){
                  if(child.children[0].classList.contains('expanded')){
                      child.children[0].classList.remove('expanded');
                      child.children[1].classList.remove('show');
                  }
              }
          }
      }
  }
  

}
