import { Injectable,EventEmitter } from '@angular/core';
//import 'rxjs/add/operator/map';
import {Router}    from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';    

@Injectable()
export class CommonFunctionsHandler {
  
  user = new BehaviorSubject(undefined);
  
  sessionLoaded = new BehaviorSubject(false);
  userdata:any = {};
  menuName=new BehaviorSubject(undefined);
  selectedMenu:any={};
  selectedItem=new BehaviorSubject(undefined);
  userHotels:any={};
  horizontalMenuData:any={};
  constructor(private router:Router) { }
  selectedHotel:string="";
  selectedInncode:string="";
  inncodeOffset={};
  inncode_hyatt_code={};
  isbandwidthTotalData=false;
  isbandwidthUpdownData=false;
  updateHotelData = false;
  dashboardRequestData={
    startDate:this.getReportStartDate(),
    endDate:new Date(),
    inncode:'',
    mInncode:'',
    iInncode:'',
    hotelData:[]
  }

  invokeFirstComponentFunction = new EventEmitter();    
  hotelUpdated = new EventEmitter();
  ihgText = "IHG";
  marriottText = "marriott";
  hyattText = "hyatt";
  subsVar: Subscription;
  onFirstComponentButtonClick() {    
    this.invokeFirstComponentFunction.emit();    
  }  

  updateHotel() {
    this.hotelUpdated.emit();
  }

  setUserMenu(menu){
    // this.menuName=menu;
    this.menuName.next(menu);
    //console.log(this.menuName);
  }
  setMenu(menu){
     this.selectedMenu=menu;
  }
  getMenu(){
    return this.selectedMenu;
  }
  setSelectedItem(item){
    // this.user.next(user) = user;
    this.selectedItem.next(item);
  }
  getSelectedItem(){
    return this.selectedItem;
  }
  setUser(user){
    // console.log("user in common");
    // console.log(user);
    // this.user.next(user) = user;
    this.user.next(user);
  }
  setUserData(user){
    this.userdata = user;
  }
  getUserData(){
    return this.userdata;
  }
  getToken() {
    return localStorage.getItem('token')
  }
  // resolveAuthGuard() : Promise<boolean>{
  //   return new Promise((resolve, reject) => {
  //     console.log('in auth guard');
  //     console.log(this.user);
  //     console.log(this.sessionLoaded);
  //     this.sessionLoaded.asObservable().subscribe((value) => {
  //       if(value){          
  //         if(this.user.getValue().loggedIn){           
  //           this.router.navigateByUrl('/home');
  //           return resolve(false);
  //         }else{            
  //           return resolve(true);
  //         }            
  //       }else{
  //         return resolve(true);
  //       }
  //     })
  //   });
  // }
  // resolveHomeGuard() : Promise<boolean>{    
  //   return new Promise((resolve, reject) => {
  //     console.log('in home guard');
  //     console.log(this.user);
  //     console.log(this.sessionLoaded);
  //     this.sessionLoaded.asObservable().subscribe((value) => {
  //       if(value){                  
  //         if(this.user.getValue().loggedIn){        
  //           return resolve(true);
  //         }else{
  //           this.router.navigateByUrl('/auth');
  //           return resolve(false);
  //         }            
  //       }else{
  //               this.router.navigateByUrl('/auth');
  //                 return resolve(false);
  //             }
  //     })
  //   });
  // }
  getCurrentYear(){
    var year = new Date().getFullYear();
    // var yearArray = [{"Year":year,"Selected":true}];
    var yearArray = [{"Year":year-1,"Selected":false},{"Year":year,"Selected":true},{"Year":year+1,"Selected":false}];
    // var yearArray = [{"Year":year,'Selected':false},{"Year":year+1,'Selected':true}];
    // var yearArray = [{"Year":year+1,"Selected":false},{"Year":year,"Selected":true},
    // {"Year":year+1,"Selected":false}];
    return yearArray;
        
  }
  replaceQuat(str){ 
    // var fIndex = str.indexOf("'");
    //  var lIndex = str.lastIndexOf("'");
  
    //  if(fIndex == 0){ str.substr(0, fIndex) + "'" + str.substr(fIndex);; }
    if (str && str.charAt(0) === "'" && str.charAt(str.length -1) === "'")
    {
        return str.substr(1,str.length -2);
    }
    else
    // if(lIndex == str.length-1){ str.replace(lIndex, ""); } 
     return str; 
  }
  //return  month array
  getCurrentMonth(){
    var monthArray = [
      {"name":"January","id":1,'shortname':'Jan'},
      {"name":"February","id":2,'shortname':'Feb'},
  {"name":"March","id":3,'shortname':'Mar'},
  {"name":"April","id":4,'shortname':'April'},
  {"name":"May","id":5,'shortname':'May'},
  {"name":"June","id":6,'shortname':'June'},
  {"name":"July","id":7,'shortname':'Jul'},
  {"name":"August","id":8,'shortname':'Aug'},
  {"name":"September","id":9,'shortname':'Sep'},
  {"name":"October","id":10,'shortname':'Oct'},
  {"name":"November","id":11,'shortname':'Nov'},
  {"name":"December","id":12,'shortname':'Dec'}];
  return monthArray;
  }
  getReportStartDate(){
    var date = new Date();
    date.setDate(date.getDate() - 15);
    return date;
  }
  getInncodeParam(innCodeArr){
    var str = '';
    var arr = innCodeArr;
    for(var i=0;i<arr.length;i++){
      if(i!=arr.length-1){
        if(arr[i]!=''){
          str+=arr[i]+',';
        }
      }else{
        if(arr[i]!=''){
          str+=arr[i];
        }
      }
    }
    var res=str;
    var n = str.lastIndexOf(",");
    var islast = false;
    if(n==str.length-1){
      islast = true
    }
    if(islast){
      res = str.substr(0, str.length-1);
    }
    return res;  
  }
  //Array with differenciated for comma
  ArrayToCommaSepwithspace(arr, key, emptyString){
    if (arr.length == 0) {
      return emptyString;
    }
    var out = "";
    var pre = "";
    
    // $.each(arr, function(index, obj) {
    //   var d = "";
    //   if (key == "") {
    //     d = obj;
    //   } else {
    //     d = obj[key];
    //   }
    //   out = out + pre + d;
    //   pre = ",";
    // });
    return out;
  }
  //Conver fromat date
 convertDate(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth()+1)).slice(-2),
        day  = ("0" + date.getDate()).slice(-2);
    return [ date.getFullYear(),mnth,day].join("-");
}
//Convert DD/MM/YYYY 
DmyFormat(date){
  if(!date){
    return false;
  }
    var dd = date.getDate();
    var mm = date.getMonth()+1; 
   //January is 0!
    var yyyy = date.getFullYear(); 
    if(dd<10){dd='0'+dd} 
    if(mm<10){mm='0'+mm} 
    var today = dd+'/'+mm+'/'+yyyy; 
  return today;
}
yyyymmddFormat(date){
  if(!date){
    return false;
  }
    var dd = date.getDate();
    var mm = date.getMonth()+1; 
   //January is 0!
    var yyyy = date.getFullYear(); 
    if(dd<10){dd='0'+dd} 
    if(mm<10){mm='0'+mm} 
    var today = yyyy+'-'+mm+'-'+dd; 
  return today;
}
//Convert DD-MM-YYYY 
Dmy(date){
    let dd;
    var mm;
    var d=new Date(date);
    dd= d.getDate();
    mm= d.getMonth()+1; 
   //January is 0!
    var yyyy = d.getFullYear(); 
    if(dd<10){
      dd='0'+dd;
    } 
    if(mm<10){mm='0'+mm;} 
    var today = dd+'-'+mm+'-'+yyyy; 
  return today;
}
isValidDate(value) {
  var dateWrapper = new Date(value);
  return !isNaN(dateWrapper.getDate());
}
findUniqueObjectOfArray(array,type){
  var unique = {};
  var distinct = [];
   for( var i in array ){
       if( typeof(unique[array[i][type]]) == "undefined"){
         distinct.push(array[i][type]);
       }
       unique[array[i][type]] = 0;
   }
   return distinct;
}
hmsToSecondsOnly(str) {
  var p = str.split(':'),
      s = 0, m = 1;

  while (p.length > 0) {
      s += m * parseInt(p.pop(), 10);
      m *= 60;
  }

  return s;
}
getPageLimit(){
  return 5;
}
getPageLimit100(){
  return 100;
}
getPageLimit30(){
  return 30;
}
getPageLimitArray(){
  return [5,10,20,30,40,50,60,70,80,90,100];
}
}
