import { Component, ViewChild} from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { ServiceHandler } from './Utility/serviceHandler.service';
import { Compiler } from '@angular/core';
import { VersionCheckService } from './version-check.service';
/**
 * @version : v1.0.0 | date : 27-6-2019 | Initial
 * @version : v1.0.1 | date : 27-6-2019 | Cache clear
 * @version : v1.0.2 | date : 27-6-2019 | Version check path change
 * @version : v1.0.3 | date : 1-7-2019 | Bug solving
 * @version : v1.0.4 | date : 1-7-2019 | Reopen Ticket issue solved
 * @version : v1.0.5 | date : 1-7-2019 | Design Issues solved
 * @version : v1.0.6 | date : 2-7-2019 | iOS Design Issues solved, bug solving
 * @version : v1.0.7 | date : 2-7-2019 | revenue graph static data set
 * @version : v1.0.8 | date : 3-7-2019 | scroll Issue solved
 * @version : v1.0.9 | date : 3-7-2019 | call summary report no data in table issue solved
 * @version : v1.0.10 | date : 5-7-2019 | Monthly summary report Client data issue solved
 * @version : v1.0.11 | date : 8-7-2019 | Marriot IHG count display on count widget
 * @version : v1.0.12 | date : 8-7-2019 | Bug Solving
 * @version : v1.0.13 | date : 9-7-2019 | Bug Solving
 * @version : v1.0.14 | date : 9-7-2019 | Dashboard Count tooltip set on full box
 * @version : v1.0.15 | date : 15-7-2019 | On Token change manually redirect to login
 * @version : v1.0.16 | date : 16-7-2019 | Changes and uptime report bug solving
 * @version : v1.0.17 | date : 16-7-2019 | export label change
 * @version : v1.0.18 | date : 17-7-2019 | rechange uptime logic, lable and query change for problem description
 * @version : v1.0.19 | date : 18-7-2019 | dashboard online offline pie chart color change
 * @version : v1.0.20 | date : 19-7-2019 | uptime display in days istead of hh:mm:ss
 * @version : v1.0.21 | date : 19-7-2019 | set call summary and reopen ticket report date as per dashboard
 * @version : v1.0.22 | date : 22-7-2019 | dashboard comparison, 95th bandwidth data issue solved
 * @version : v1.0.23 | date : 25-7-2019 | case report view all input issue solved
 * @version : v1.0.24 | date : 26-7-2019 | Marriott case Report, Marriott call and ticket summary added
 * @version : v1.0.25 | date : 26-7-2019 | Differentiat ihg and marriott hotel
 * @version : v1.0.26 | date : 29-7-2019 | Bug solving
 * @version : v1.0.27 | date : 30-7-2019 | Bug solving
 * @version : v1.0.28 | date : 30-7-2019 | Bug solving
 * @version : v1.0.29 | date : 2-8-2019 | Bug solving
 * @version : v1.0.30 | date : 2-8-2019 | Bug solving
 * @version : v1.0.31 | date : 2-8-2019 | Make brand input select instead of dropdown for hotel
 * @version : v1.0.32 | date : 7-8-2019 | Bug solving
 */
//ng build && ng build --prod --aot --output-hashing=all && npm run post-build
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
   ready:boolean;
  constructor(public appSettings:AppSettings,private serviceHandler:ServiceHandler,
    private _compiler: Compiler,private versionCheckService:VersionCheckService){
      this.settings = this.appSettings.settings;
      this._compiler.clearCache();
      this.serviceHandler.load().then(() => {
        this.ready = true;
      }).catch(() => {
        
      })
  } 
  ngOnInit() {
    const environment = {
      production: true,
      versionCheckURL : ""
    };
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
   }
}