import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent } from '@angular/common/http';

import {Observable} from 'rxjs';

import { CommonFunctionsHandler } from './commonFunctions.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector,private cfh:CommonFunctionsHandler){}
  //retrive token from local storage
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    //const auth = this.inj.get(AuthenticationService);
    //const authToken = auth.getAuthorizationToken();
    let authService = this.injector.get(CommonFunctionsHandler)
    // var staticToken = "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWJqZWN0IjoxLCJpYXQiOjE1NDk5NjEyMzd9.GE2ytg4BuCuRAmNaUo5YXNDn-jpWsFTevg20GgFZ2K8"

    let tokenizedReq = req.clone(
      {
        headers: req.headers.set('Authorization', 'bearer ' + authService.getToken())
        // headers: req.headers.set('Authorization', staticToken)
      }
    )
    return next.handle(tokenizedReq)
  }
}
