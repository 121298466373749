import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRoute,Router,
  ActivatedRouteSnapshot,RouterStateSnapshot }    from '@angular/router';

import {CommonFunctionsHandler} from '../Utility/commonFunctions.service';
import {ServiceHandler} from '../Utility/serviceHandler.service';

@Injectable()
export class AuthGuard implements CanActivate {
    
  constructor(private router:Router,private cfh:CommonFunctionsHandler,private sh : ServiceHandler){}

  canActivate() : Promise<boolean> {
    return new Promise((resolve, reject) => {
      //console.log('in auth guard');
      //console.log(this.cfh.user.getValue());
      // this.cfh.sessionLoaded.asObservable().subscribe((value) => {
      //   if(value){
          // console.log('in auth guard can active');
          // console.log("user : "+this.cfh.user.getValue()+" | login : "+this.cfh.user.getValue());
            if( this.cfh.user.getValue() && this.cfh.user.getValue().loggedIn){
              //console.log('go for api calling');
              //this.sh.load().then(() => {
                this.router.navigateByUrl('/home');
                return resolve(false);
              // }).catch(() => {
              //   return resolve(true);
              // })        
              // this.router.navigateByUrl('/home');
              // return resolve(false);
            }else{ 
              //console.log('vlaue get false');    
              return resolve(true);
            }            
      //     }else{
      //       return resolve(true);
      //     }
      // });
    });
  }  
}

@Injectable()
export class HomeGuard implements CanActivate,CanActivateChild {
  
  constructor(private router:Router,private cfh:CommonFunctionsHandler,private sh : ServiceHandler){}

  canActivate() : Promise<boolean> {
    return new Promise((resolve, reject) => {
     // console.log('in home guard');
      this.sh.load().then(() => {
        this.cfh.sessionLoaded.asObservable().subscribe((value) => {
          // console.log('in home guard can active');
          //   console.log("user : "+value+" | login : "+this.cfh.user.getValue());
          if(value){                  
            if(this.cfh.user.getValue() && this.cfh.user.getValue().loggedIn){        
              return resolve(true);
            }else{
              this.router.navigateByUrl('/auth');
              return resolve(false);
            }            
          }else{
            this.router.navigateByUrl('/auth');
            return resolve(false);
          }
        });
      })
      
    });
  }

  canActivateChild() : Promise<boolean> {              
    return new Promise((resolve, reject) => {
      return resolve(true);
      this.cfh.sessionLoaded.asObservable().subscribe((value) => {
        // console.log('in home guard can active child');
        //   console.log("user : "+value+" | login : "+this.cfh.user.getValue());
        if(value){                  
          if(this.cfh.user.getValue() && this.cfh.user.getValue().loggedIn){        
            return resolve(true);
          }else{
            this.router.navigateByUrl('/auth');
            return resolve(false);
          }            
        }else{
          this.router.navigateByUrl('/auth');
          return resolve(false);
        }
      });
    });
  }
}


@Injectable()
export class ModuleGuard implements CanActivate {
  
  constructor(private router:Router,private cfh:CommonFunctionsHandler,
    private sh : ServiceHandler){}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean> {
    return new Promise((resolve, reject) => {
        // console.log('in module guard');
        // return resolve(true);
        // console.log('in ModuleGuard guard can active');
        //   console.log("user : "+this.cfh.user.getValue()+" | login : "+this.cfh.user.getValue());
        if(this.cfh.user.getValue() && this.cfh.user.getValue().loggedIn){
          //console.log('go for api calling');
          this.sh.load().then(() => {
          //   console.log('in ModuleGuard load method call');
          // console.log("user : "+this.cfh.user.getValue());
            //this.router.navigateByUrl('/home');
            this.cfh.horizontalMenuData = this.cfh.user.getValue().user_details.modules;
            // var menu = [];
            // var modules = this.cfh.user.value.user_details.modules;
            // var index = 1;
            // for(var i=0;i<modules.length;i++){
            //   if(!modules[i].for_admin){
            //     var item = new Menu (index, modules[i].module_name, '/home/'+modules[i].module_name, null, modules[i].icon, null, false, 0);
            //     menu.push(item);
            //     index++;
            //   }
            // }
            // if(this.cfh.user.value.user_details.is_admin){
            //   var item = new Menu (index, 'Admin', '/ui/lists', null, 'verified_user', null, true, 0); 
            //   var adminIndex = index;
            //   menu.push(item);
            //   index++;
            //   for(var i=0;i<modules.length;i++){
            //     if(modules[i].for_admin){
            //       //var item = new Menu (index, modules[i].module_name, '/home/'+modules[i].module_name, null, 'keyboard', null, false, 0);
            //       var item = new Menu (index, modules[i].module_name, '/home/'+modules[i].module_name, null, modules[i].icon, null, false, adminIndex);
            //       menu.push(item);
            //       index++;
            //     }
            //   }
            // }
            //this.hmc.menuItems = menu;
            return resolve(true);
          }).catch(() => {
            return resolve(false);
          })        
          // this.router.navigateByUrl('/home');
          // return resolve(false);
        }else{ 
          //console.log('vlaue get false');    
          return resolve(false);
        }   
        // this.cfh.sessionLoaded.asObservable().subscribe((value) => {
        //   // console.log("value in module guard");
        //   console.log(value);
        //   // console.log(JSON.stringify(this.cfh.userdata));
        //   if(value){ 
        //     var url = state.url;
        //     var module = url.split("/")[2].toUpperCase();
            
        //     var allowRouting= false;
        //     var defaultRoute = "dashboard";
            
        //     // for (var key in this.cfh.userdata.role_details.module_data) {
        //     //     defaultRoute = module.toLowerCase();
        //     //     if(key.toUpperCase() == module){
        //     //       allowRouting=true;
        //     //       break;
        //     //     }
        //     //   }
        //     if(this.cfh.user.getValue() && (this.cfh.userdata.is_admin)){   
        //       return resolve(true);
        //     }else{
        //       this.router.navigateByUrl('/home');
        //       return resolve(false);
        //     }            
        //   }
        //})
      });
    
  }
}
