import { Component, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'common-error-dialog',
  templateUrl: 'dialog-common-dialog.html',
})

export class DialogCommonDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogCommonDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log('open dialog');
      console.log(data);
    }

    onNoClick(): void {
      console.log('close it')
      this.dialogRef.close(false);
    }

}